














































import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkPlatformService } from '@WorkPlatform/api/index';

import { namespace } from 'vuex-class';

import { service } from '@cloudpivot-hermes/nail-free-login';

import { isPhone } from '@WorkPlatform/common/utils';

import SelectModal from './select-modal.vue';
import { appForward } from '@WorkPlatform/service/flow-center';
import { EClientType } from '@WorkPlatform/common/const';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'recentList',
  components: {
    AppImage: () => import('./app-image.vue'),
    SelectModal,
  },
})
export default class RecentList extends Vue {
  @workPlatformModule.State('recentIds') recentIds: any;

  @workPlatformModule.Action('setRecentIds') setRecentIds: any;

  @Prop({ default: false }) hasRunningApp!: boolean;

  @Prop({ default: () => [] }) appList!: any;

  showAppSelect = false;

  recentUsedApp = [] as any;

  appBgStyle = {} as any;

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return (
      sessionStorage.IS_DINGTALK === 'true' ||
      sessionStorage.IS_WECHAT === 'true'
    );
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  setStyle (appInfo, isHover) {
    const { backgroundImage } = appInfo.style;
    const strReg = /color\d+/i;
    const colorStr = backgroundImage.match(strReg).pop();
    if (isHover) {
      appInfo.style.backgroundImage = `url(${require(`@/assets/app-used/${colorStr}_hover.png`)})`;
    } else {
      appInfo.style.backgroundImage = `url(${require(`@/assets/app-used/${colorStr}.png`)})`;
    }
  }

  calcStyle (appInfo, data) {
    const logoUrl = appInfo.logoUrl;
    const strReg = /color-\d/i;
    const nonColorList = data.filter(
      (item) => !item.logoUrl || !strReg.test(item.logoUrl),
    );
    let bgImgUrl = '';
    let idx = 1;
    if (!logoUrl || !strReg.test(logoUrl)) {
      nonColorList.some((app: any, index: number) => {
        if (app.id === appInfo.id) {
          // 每7个一个logoUrl没有值的一个轮回
          idx = index + 1;
          idx = idx % 7 === 0 ? 7 : idx % 7;
          return true;
        }
      });
      bgImgUrl = require(`@/assets/app-used/color${idx}.png`);
    } else {
      bgImgUrl = require(`@/assets/app-used/${logoUrl
        .match(strReg)
        .pop()
        .replace('-', '')}.png`);
    }
    return {
      backgroundImage: `url(${bgImgUrl})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    };
  }

  created () {
    this.getUsedAllList();
  }

  beforeDestroy () {
    const token: any = localStorage.getItem('token');

    if (token) return;
    // 退出登录清空最近使用的app
    this.setRecentIds([]);
  }

  addUsedApp (appCodes: any) {
    WorkPlatformService.addUsedApp({ appCodes }).then((res) => {
      if (res.success) {
        this.$message.success('添加成功');
        this.getUsedAllList();
      } else {
        this.$message.error(res.errMessage);
      }
    });
  }

  getUsedAllList () {
    WorkPlatformService.usedAppList().then((res: any) => {
      let temp = res?.data || [];
      temp = temp.map((item) => {
        return {
          ...item,
          style: this.calcStyle(item, temp),
        };
      });
      this.recentUsedApp = temp;
      this.$emit('dataLoad');
    });
  }

  clickHandler (item: any) {
    appForward(item, EClientType.PC, item.homepages?.pcHomepage);
  }

  setRecentApp (id: string) {
    const ids: string | null = this.recentIds;
    let idsArr: any[] = [];
    if (Array.isArray(ids)) {
      idsArr = ids.filter((item: any) => item !== id);
    }
    idsArr.unshift(id);
    this.setRecentIds(idsArr);
  }
}
